<template>
  <div class="page flex-col">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>我的账户</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/myOrder' }"
        >我的订单</el-breadcrumb-item
      >
      <el-breadcrumb-item>订单编号：{{ orderData.orderId }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div
      v-if="orderData.status != '2' && orderData.status != '4' && active"
      class="steps-view flex-row"
    >
      <div
        :class="{ 'steps-item': true, isActive: active >= 1 ? true : false }"
      >
        <div class="index">1</div>
        <span>提交订单</span>
        <div class="dashed"></div>
      </div>
      <div
        :class="{ 'steps-item': true, isActive: active >= 1 ? true : false }"
      >
        <div class="index">2</div>
        <span>进行付款</span>
        <div class="dashed"></div>
      </div>
      <div
        :class="{ 'steps-item': true, isActive: active >= 2 ? true : false }"
      >
        <div class="index">3</div>
        <span>订单审核</span>
        <div class="dashed"></div>
      </div>
      <div
        :class="{ 'steps-item': true, isActive: active >= 3 ? true : false }"
      >
        <div class="index">4</div>
        <span>广告投放</span>
        <div class="dashed"></div>
      </div>
      <div
        :class="{ 'steps-item': true, isActive: active == 4 ? true : false }"
      >
        <div class="index">5</div>
        <span>订单结束</span>
      </div>
    </div>
    <div class="orderStatus flex-row" v-if="orderData.status == '0'">
      <img src="@/assets/icon/order_status_0.png" alt="" />
      <div class="main-data flex-col">
        <p>订单状态: 已提交订单，等待付款</p>
        <div class="order-time flex-row align-center">
          请在
          <div class="time">{{ minute }}分钟{{ second }}秒</div>
          内付款，否则订单会被自动取消。
        </div>
        <div class="flex-row btn-group">
          <div class="pay-btn" id="primary-btn" @click="pay">立即支付</div>
          <div
            class="cancel-btn"
            id="default-btn"
            @click="onCancelOrder(orderData.orderId)"
          >
            取消订单
          </div>
        </div>
      </div>
      <div class="share flex-row align-center" @click="onShareOrder">
        <img class="share-btn" src="@/assets/img/share.png" alt="" />
        <span class="text-btn">分享订单</span>
      </div>
    </div>
    <div
      class="orderStatus flex-row"
      v-if="orderData.status == '1' || orderData.status == '6'"
    >
      <img src="@/assets/icon/order_status_1.png" alt="" />
      <div class="main-data flex-col">
        <p>订单状态: 广告内容正在审核中</p>
        <div class="bg-desc" v-if="isBetween && !orderData.direct">
          我们将会在今天内完成审核，请您耐心等待。若审核通过，在订单中再次投放该视频无需审核，最快可5分钟后投放。
        </div>
        <div class="bg-desc" v-if="!isBetween && !orderData.direct">
          我们将会在明日大屏开机时间之前完成审核，请您耐心等待。若审核通过，在订单中再次投放该视频无需审核，最快可5分钟后投放。
        </div>
        <div class="button" id="primary-btn" @click="onRelaunch">再次投放</div>
      </div>
      <div class="share flex-row align-center" @click="onShareOrder">
        <img class="share-btn" src="@/assets/img/share.png" alt="" />
        <span class="text-btn">分享订单</span>
      </div>
    </div>
    <div class="orderStatus flex-row" v-if="orderData.status == '2'">
      <img src="@/assets/icon/order_status_2.png" alt="" />
      <div class="main-data flex-col">
        <p>订单状态: 订单已取消</p>
        <!-- <span class="desc" v-if="orderData.isHandCancel"
          >您手动取消了订单；若有需要，您可再次投放。</span
        > -->
        <span class="desc" v-if="!orderData.isHandCancel && !overBeginUnix"
          >因超时未支付已被系统取消；若有需要，您可再次投放。</span
        >
        <span class="desc" v-if="!orderData.isHandCancel && overBeginUnix"
          >因支付时间超过第一次投放时间，订单支付失败已被取消；若有需要，您可再次投放。</span
        >
        <div class="button" id="primary-btn" @click="onRelaunch">再次投放</div>
      </div>
      <div class="share flex-row align-center" @click="onShareOrder">
        <img class="share-btn" src="@/assets/img/share.png" alt="" />
        <span class="text-btn">分享订单</span>
      </div>
    </div>
    <div class="orderStatus flex-row" v-if="orderData.status == '3'">
      <img src="@/assets/icon/order_status_3.png" alt="" />
      <div class="main-data flex-col">
        <p>订单状态: 广告正在投放中</p>
        <span class="desc">您可以使用此订单的广告视频和信息，再次进行投放</span>
        <div class="button" id="primary-btn" @click="onRelaunch">再次投放</div>
      </div>
      <div class="pdf flex-row align-center" @click="onDownloadPDF">
        <img class="pdf-btn" src="@/assets/icon/pdf.png" alt="" />
        <span class="text-btn">导出pdf文件</span>
      </div>
      <div class="share flex-row align-center" @click="onShareOrder">
        <img class="share-btn" src="@/assets/img/share.png" alt="" />
        <span class="text-btn">分享订单</span>
      </div>
    </div>
    <div
      v-if="
        orderData.status == '3' &&
        !orderData.hasAdPlaying &&
        orderData.hasNextAd
      "
      class="explain-view flex-row align-center"
    >
      距离下一次投放还有
      <div v-if="days > 0">
        <span>{{ days }}</span
        >天
      </div>
      <div v-if="hours > 0">
        <span>{{ hours }}</span
        >小时
      </div>
      <div v-if="minutes > 0">
        <span>{{ minutes }}</span
        >分
      </div>
      <div>
        <span>{{ seconds }}</span
        >秒
      </div>
    </div>
    <div class="orderStatus flex-row" v-if="orderData.status == '4'">
      <img src="@/assets/icon/order_status_4.png" alt="" />
      <div class="main-data flex-col">
        <p>抱歉，您的订单未审核通过</p>
        <span class="desc">支付金额原路退回</span>
        <span
          v-if="orderData.rejectStep == 1"
          class="desc"
          style="margin-top: 25rem"
          >您投放的视频未审核通过，原因如下：{{
            orderData.adminAuditReason
          }}。如若需要我们的帮助，请联系客服。</span
        >
        <span v-else class="desc" style="margin-top: 25rem">{{
          orderData.adminAuditReason
        }}</span>
        <!-- <div
          class="desc"
          style="margin-top: 14rem"
          v-if="orderData.designId && orderData.adminAuditUserId != 'System'"
        >
          对于在线编辑的视频，我们支持用户将违规部分在线修改后，再次进行投放。
          <div class="tips flex-row align-center" @click="onAmend">
            <u>点击前往修改并再次投放</u>
            <img src="@/assets/icon/two_jiantou.png" alt="" />
          </div>
        </div> -->
        <div
          v-if="orderData.adminAuditUserId == 'System'"
          class="button"
          id="primary-btn"
          @click="onRelaunch"
        >
          再次投放
        </div>
      </div>
      <!-- <div class="share flex-row align-center" @click="onShareOrder">
        <img class="share-btn" src="@/assets/img/share.png" alt="" />
        <span class="text-btn">分享订单</span>
      </div> -->
    </div>
    <div class="orderStatus flex-row" v-if="orderData.status == '5'">
      <img src="@/assets/icon/order_status_5.png" alt="" />
      <div class="main-data flex-col">
        <p v-if="orderData.isAdminHandOver">订单状态: 广告已被提前结束投放</p>
        <p v-else>订单状态: 广告已结束投放</p>
        <span v-if="!orderData.isAdminHandOver" class="desc"
          >您可以使用此订单的广告视频和信息，再次进行投放</span
        >
        <span v-if="orderData.isAdminHandOver" class="desc">{{
          orderData.adminHandOverReason
        }}</span>
        <!-- <div
          class="desc"
          style="margin-top: 14rem"
          v-if="orderData.designId && orderData.isAdminHandOver"
        >
          对于在线编辑的视频，我们支持用户将违规部分在线修改后，再次进行投放。
          <div class="tips flex-row align-center" @click="onAmend">
            <u>点击前往修改并再次投放</u
            ><img src="@/assets/icon/two_jiantou.png" alt="" />
          </div>
        </div> -->
        <div
          v-if="!orderData.isAdminHandOver"
          class="button"
          id="primary-btn"
          @click="onRelaunch"
        >
          再次投放
        </div>
      </div>
      <div class="pdf flex-row align-center" @click="onDownloadPDF">
        <img class="pdf-btn" src="@/assets/icon/pdf.png" alt="" />
        <span class="text-btn">导出pdf文件</span>
      </div>
      <div class="share flex-row align-center" @click="onShareOrder">
        <img class="share-btn" src="@/assets/img/share.png" alt="" />
        <span class="text-btn">分享订单</span>
      </div>
    </div>
    <div
      class="refund-progress"
      v-if="orderData.status == '4' && orderData.payAmount > 0"
    >
      <div class="refund-title">退款进度</div>
      <el-timeline :reverse="reverse">
        <el-timeline-item
          :timestamp="orderData.refundList[0].createTime | dateFormat"
        >
          1.平台申请退款
        </el-timeline-item>
        <el-timeline-item
          :timestamp="orderData.refundList[0].auditTime | dateFormat"
        >
          <span
            >2.{{
              orderData.payType == "1"
                ? "微信"
                : orderData.payType == "2"
                ? "支付宝"
                : orderData.payType == "3"
                ? "花呗"
                : orderData.payType == "4"
                ? "支付宝"
                : ""
            }}第三方平台处理退款</span
          >
        </el-timeline-item>
        <el-timeline-item
          :timestamp="orderData.refundList[0].refundTime | dateFormat"
        >
          <span
            >3.{{
              orderData.refundList[0].status == "0"
                ? "待处理"
                : orderData.refundList[0].status == "1"
                ? "申请已通过"
                : orderData.refundList[0].status == "2"
                ? "申请不通过"
                : orderData.refundList[0].status == "3"
                ? "退款成功"
                : orderData.refundList[0].status == "4"
                ? "退款失败"
                : orderData.refundList[0].status == "5"
                ? "系统自动申请通过"
                : "未知状态"
            }}</span
          >
          <span class="desc" v-if="orderData.refundList[0].status == '3'">{{
            refundTimeDesc
          }}</span>
        </el-timeline-item>
      </el-timeline>
    </div>
    <div
      v-if="
        orderData.status == '0' ||
        orderData.status == '1' ||
        orderData.status == '6'
      "
      class="ad-view flex-col"
    >
      <div class="ad-titlt flex-row align-center">
        <span>所有投放时间：</span>
        <div
          :class="{
            desc: true,
            grey:
              orderData.status == '1' || orderData.status == '6' ? true : false,
          }"
        >
          付款即表示您同意在这些时间点进行广告投放
        </div>
      </div>
      <div
        class="ad-list"
        v-infinite-scroll="launchTimeLoad"
        :infinite-scroll-disabled="infiniteScrollDisabled"
        infinite-scroll-distance="1"
      >
        <span v-for="(item, index) in adList" :key="index" class="adItem">{{
          item.beginUnix | dateFormat
        }}</span>
      </div>
      <div v-if="adList.length > 18" class="bg"></div>
    </div>
    <div
      class="adTable"
      v-if="orderData.status == '3' || orderData.status == '5'"
    >
      <el-tabs v-model="currentTab" @tab-click="handleClick">
        <el-tab-pane label="全部投放" name="all" />
        <el-tab-pane :label="'待投放（' + status3Num + '）'" name="3" />
        <el-tab-pane :label="'投放成功（' + status6Num + '）'" name="6" />
        <el-tab-pane :label="'投放失败（' + status7Num + '）'" name="7" />
        <el-tab-pane
          v-if="orderData.status == '5'"
          :label="'投放取消（' + status2Num + '）'"
          name="2"
        />
      </el-tabs>
      <div
        class="table-tips flex-row justify-between"
        v-if="
          orderData.status == '5' &&
          showTips &&
          (orderData.failTimes > 0 || orderData.cancelTimes > 0) &&
          !orderData.superVip &&
          !orderData.refundZero
        "
      >
        <div class="tips-text flex-row">
          <img class="tips-icon" src="@/assets/icon/warning_green.png" alt="" />
          <span
            v-if="!orderData.isAdminHandOver && !orderData.refundList.length"
          >
            因设备或网络故障，
            <span v-if="orderData.failTimes > 0">投放失败{{ orderData.failTimes }}次，</span>
            <span v-if="orderData.cancelTimes > 0">投放取消{{ orderData.cancelTimes }}次，</span>
            客服正在为您退款或退时长，预计24小时内完成。</span
          >
          <span
            v-if="
              !orderData.isAdminHandOver &&
              orderData.refundList.length &&
              (orderData.refundList[0].status == '0' ||
                orderData.refundList[0].status == '1' ||
                orderData.refundList[0].status == '5')
            "
          >
            因设备或网络故障，
            <span v-if="orderData.failTimes > 0">投放失败{{ orderData.failTimes }}次，</span>
            <span v-if="orderData.cancelTimes > 0">投放取消{{ orderData.cancelTimes }}次，</span>
            客服正在为您退款或退时长，预计24小时内完成。</span
          >
          <span
            v-if="
              !orderData.isAdminHandOver &&
              orderData.refundList.length &&
              orderData.refundList[0].status == '4'
            "
          >
            因设备或网络故障，
            <span v-if="orderData.failTimes > 0">投放失败{{ orderData.failTimes }}次，</span>
            <span v-if="orderData.cancelTimes > 0">投放取消{{ orderData.cancelTimes }}次，</span>
            大屏时代的服务器端返回出错，等待人工处理退款，最迟不超过24小时。</span
          >
          <span
            v-if="
              !orderData.isAdminHandOver &&
              orderData.refundList.length &&
              orderData.refundList[0].status == '2'
            "
          >
            因设备或网络故障，
            <span v-if="orderData.failTimes > 0">投放失败{{ orderData.failTimes }}次，</span>
            <span v-if="orderData.cancelTimes > 0">投放取消{{ orderData.cancelTimes }}次，</span>
            但很遗憾您的退款申请未通过审核，如有疑问请联系客服support@daping.cn。</span
          >
          <span
            v-if="
              !orderData.isAdminHandOver &&
              orderData.refundList.length &&
              orderData.refundList[0].status == '3' &&
              orderData.refundList[0].refundAmount > 0 &&
              orderData.refundList[0].refundSecond == 0
            "
          >
            因设备或网络故障，
            <span v-if="orderData.failTimes > 0">投放失败{{ orderData.failTimes }}次，</span>
            <span v-if="orderData.cancelTimes > 0">投放取消{{ orderData.cancelTimes }}次，</span>
            已将¥{{
              orderData.refundList[0].refundAmount
            }}返回至原支付账号，预计24小时内到账，请注意查收。</span
          >
          <span
            v-if="
              !orderData.isAdminHandOver &&
              orderData.refundList.length &&
              orderData.refundList[0].status == '3' &&
              orderData.refundList[0].refundSecond > 0 &&
              orderData.refundList[0].refundAmount == 0
            "
            @click="goDurationTable"
            style="cursor: pointer"
          >
            因设备或网络故障，
            <span v-if="orderData.failTimes > 0">投放失败{{ orderData.failTimes }}次，</span>
            <span v-if="orderData.cancelTimes > 0">投放取消{{ orderData.cancelTimes }}次，</span>
            已将{{
              orderData.refundList[0].refundSecond
            }}秒时长退回至您的账户，请前往时长明细查看，前往查看>></span
          >
          <span
            v-if="
              !orderData.isAdminHandOver &&
              orderData.refundList.length &&
              orderData.refundList[0].status == '3' &&
              orderData.refundList[0].refundAmount > 0 &&
              orderData.refundList[0].refundSecond > 0
            "
            @click="goDurationTable"
            style="cursor: pointer"
          >
            因设备或网络故障，
            <span v-if="orderData.failTimes > 0">投放失败{{ orderData.failTimes }}次，</span>
            <span v-if="orderData.cancelTimes > 0">投放取消{{ orderData.cancelTimes }}次，</span>
            退款¥{{ orderData.refundList[0].refundAmount }}及时长{{
              orderData.refundList[0].refundSecond
            }}秒已原路退回。退款预计24小时内到账，请注意查收；退回时长可前往时长明细查看，前往查看>></span
          >
          <span
            v-if="orderData.isAdminHandOver && !orderData.refundList.length"
          >
            订单已被提前结束，
            <span v-if="orderData.failTimes > 0">投放失败{{ orderData.failTimes }}次，</span>
            <span v-if="orderData.cancelTimes > 0">投放取消{{ orderData.cancelTimes }}次，</span>
            客服正在为您退款或退时长，预计24小时内完成。</span
          >
          <span
            v-if="
              orderData.isAdminHandOver &&
              orderData.refundList.length &&
              (orderData.refundList[0].status == '0' ||
                orderData.refundList[0].status == '1' ||
                orderData.refundList[0].status == '5')
            "
          >
            订单已被提前结束，
            <span v-if="orderData.failTimes > 0">投放失败{{ orderData.failTimes }}次，</span>
            <span v-if="orderData.cancelTimes > 0">投放取消{{ orderData.cancelTimes }}次，</span>
            客服正在为您退款或退时长，预计24小时内完成。</span
          >
          <span
            v-if="
              orderData.isAdminHandOver &&
              orderData.refundList.length &&
              orderData.refundList[0].status == '4'
            "
          >
            订单已被提前结束，
            <span v-if="orderData.failTimes > 0">投放失败{{ orderData.failTimes }}次，</span>
            <span v-if="orderData.cancelTimes > 0">投放取消{{ orderData.cancelTimes }}次，</span>
            大屏时代的服务器端返回出错，等待人工处理退款，最迟不超过24小时。</span
          >
          <span
            v-if="
              orderData.isAdminHandOver &&
              orderData.refundList.length &&
              orderData.refundList[0].status == '2'
            "
          >
            订单已被提前结束，
            <span v-if="orderData.failTimes > 0">投放失败{{ orderData.failTimes }}次，</span>
            <span v-if="orderData.cancelTimes > 0">投放取消{{ orderData.cancelTimes }}次，</span>
            但很遗憾您的退款申请未通过审核，如有疑问请联系客服support@daping.cn。
          </span>
          <span
            v-if="
              orderData.isAdminHandOver &&
              orderData.refundList.length &&
              orderData.refundList[0].status == '3' &&
              orderData.refundList[0].refundAmount > 0 &&
              orderData.refundList[0].refundSecond == 0
            "
          >
            订单已被提前结束，
            <span v-if="orderData.failTimes > 0">投放失败{{ orderData.failTimes }}次，</span>
            <span v-if="orderData.cancelTimes > 0">投放取消{{ orderData.cancelTimes }}次，</span>
            已将¥{{
              orderData.refundList[0].refundAmount
            }}返回至原支付账号，预计24小时内到账，请注意查收。
          </span>
          <span
            v-if="
              orderData.isAdminHandOver &&
              orderData.refundList.length &&
              orderData.refundList[0].status == '3' &&
              orderData.refundList[0].refundSecond > 0 &&
              orderData.refundList[0].refundAmount == 0
            "
            @click="goDurationTable"
            style="cursor: pointer"
          >
            订单已被提前结束，
            <span v-if="orderData.failTimes > 0">投放失败{{ orderData.failTimes }}次，</span>
            <span v-if="orderData.cancelTimes > 0">投放取消{{ orderData.cancelTimes }}次，</span>
            已将{{
              orderData.refundList[0].refundSecond
            }}秒时长退回至您的账户，请前往时长明细查看，前往查看>></span
          >
          <span
            v-if="
              orderData.isAdminHandOver &&
              orderData.refundList.length &&
              orderData.refundList[0].status == '3' &&
              orderData.refundList[0].refundAmount > 0 &&
              orderData.refundList[0].refundSecond > 0
            "
            @click="goDurationTable"
            style="cursor: pointer"
          >
            订单已被提前结束，
            <span v-if="orderData.failTimes > 0">投放失败{{ orderData.failTimes }}次，</span>
            <span v-if="orderData.cancelTimes > 0">投放取消{{ orderData.cancelTimes }}次，</span>
            退款¥{{ orderData.refundList[0].refundAmount }}及时长{{
              orderData.refundList[0].refundSecond
            }}秒已原路退回。退款预计24小时内到账，请注意查收；退回时长可前往时长明细查看，前往查看>></span
          >
        </div>
        <div
          class="close-icon flex-row justify-center align-center"
          @click="showTips = false"
        >
          <img src="@/assets/icon/close.png" alt="" />
        </div>
      </div>
      <el-table
        class="el-table"
        :data="adList"
        max-height="300"
        v-loadMoreTable="launchTimeLoad"
      >
        <el-table-column prop="adId" align="left" width="200" label="投放编号">
        </el-table-column>
        <el-table-column prop="beginUnix" align="center" label="投放时间">
          <template v-slot="{ row }">
            <span>{{ row.beginUnix | dateFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderId" align="center" label="所属订单编号">
        </el-table-column>
        <el-table-column
          prop="status"
          align="right"
          width="200"
          label="投放状态"
        >
          <template v-slot="{ row }">
            <span>{{
              row.status == "0"
                ? "待支付"
                : row.status == "1"
                ? "审核中"
                : row.status == "2" || row.status == "4" || row.status == "5"
                ? "投放取消"
                : row.status == "3"
                ? "待投放"
                : row.status == "6"
                ? "投放成功"
                : row.status == "7"
                ? "投放失败"
                : row.status == "8"
                ? "待二次审核"
                : row.status == "9"
                ? "投放中"
                : "未知"
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div
      class="refund-tips"
      v-if="
        orderData.status == '0' ||
        orderData.status == '1' ||
        orderData.status == '6' ||
        orderData.status == '3'
      "
    >
      <div class="refund-tips-titlt flex-row align-center">
        <span>退款说明</span>
      </div>
      <p>
        因设备或网络故障导致投放失败的部分，以及提前结束订单被取消投放的部分，我们将会在全部投放结束后24小时之内，按支付比例退款退时长，退款及时长原路退回。
      </p>
    </div>
    <div class="orderTable flex-col" v-if="orderData.orderId">
      <!-- <h4>投放订单信息</h4> -->
      <el-divider class="divider1"></el-divider>
      <div class="table-top flex-row justify-between">
        <div class="flex-col justify-between">
          <span>广告内容</span>
          <div class="ad-img justify-center">
            <img class="logo_seize" src="@/assets/img/logo_seize.png" alt="" />
            <img
              class="img"
              :src="orderData.videoThumbnailUrl"
              alt=""
              @click="onClick_see(orderData)"
            />
            <div
              v-if="orderData.status != '4'"
              class="editVideoCover"
              @click.stop="editVideoCover"
            >
              修改封面
            </div>
          </div>
        </div>
        <div class="flex-col justify-between align-center">
          <span>投放广告的屏幕</span>
          <span>{{ orderData.name | screenFilter }}</span>
        </div>
        <div
          v-if="showTypeMap.length"
          class="flex-col justify-between align-center"
        >
          <span>视频显示方式</span>
          <div v-if="showTypeMap.length == 1" style="height: auto">
            <span style="color: #252927">{{
              showTypeMap[0][1] == 0
                ? "居中"
                : showTypeMap[0][1] == 1
                ? "平铺"
                : "拉伸"
            }}</span>
          </div>
          <div v-else class="flex-col align-center" style="height: auto">
            <span style="color: #252927"
              >主屏：{{
                showTypeMap[0][1] == 0
                  ? "居中"
                  : showTypeMap[0][1] == 1
                  ? "平铺"
                  : "拉伸"
              }}</span
            >
            <span style="color: #252927; position: relative; top: 20rem"
              >辅屏：{{
                showTypeMap[1][1] == 0
                  ? "居中"
                  : showTypeMap[1][1] == 1
                  ? "平铺"
                  : "拉伸"
              }}</span
            >
          </div>
        </div>
        <div class="flex-col justify-between align-center">
          <span>投放方式</span>
          <span v-if="orderData.smart">智能投放</span>
          <span v-else>常规投放</span>
        </div>
        <div class="flex-col justify-between align-center">
          <span v-if="orderData.smart">实际投放次数</span>
          <span v-else>投放总次数</span>
          <span>{{ orderData.times }}次</span>
        </div>
        <div
          v-if="!orderData.smart"
          class="flex-col justify-between align-center"
        >
          <span>投放时间间隔</span>
          <span v-if="orderData.times > 1 && orderData.intervalMinute == 0"
            >不间隔，连续投放</span
          >
          <span v-else-if="orderData.times > 1"
            >每{{
              orderData.intervalMinute == 1440
                ? "1天"
                : orderData.intervalMinute >= 60
                ? orderData.intervalMinute / 60 + "小时"
                : orderData.intervalMinute + "分钟"
            }}投放1次</span
          >
          <span v-else>-</span>
        </div>
        <div
          v-if="orderData.smart"
          class="flex-col justify-between align-center"
        >
          <span>投放时间间隔</span>
          <span v-if="orderData.times > 1 && orderData.intervalMinute == 0"
            >不间隔，连续投放</span
          >
          <span v-else-if="orderData.times > 1 && orderData.smartDaysTimes > 1"
            >每{{ orderData.intervalMinute }}分钟投放1次</span
          >
          <span v-else-if="orderData.times > 1 && orderData.smartDaysTimes == 1"
            >每1天投放1次</span
          >
          <span v-else>-</span>
        </div>
        <div class="flex-col justify-between align-center">
          <span>开始投放时间</span>
          <span>{{ orderData.beginUnix | dateFormat }}</span>
        </div>
        <div class="flex-col justify-between align-end">
          <span>计费单价</span>
          <span>每秒 ¥1.00</span>
        </div>
      </div>
      <el-divider class="divider2"></el-divider>
      <div class="table-bottom flex-row justify-between">
        <div class="left-desc flex-col">
          <span>提交时间：{{ orderData.createTime | dateFormat }}</span>
          <span>订单编号：{{ orderData.orderId }}</span>
          <span v-if="orderData.status != '0' && orderData.status != '2'"
            >支付方式：{{
              orderData.payType == "1"
                ? "微信支付"
                : orderData.payType == "2"
                ? "支付宝"
                : orderData.payType == "3"
                ? "花呗支付"
                : orderData.payType == "4"
                ? "支付宝"
                : "-"
            }}</span
          >
          <span v-if="orderData.status != '0' && orderData.status != '2'">
            付款时间：{{ orderData.payTime | dateFormat }}</span
          >
        </div>
        <div class="right-desc flex-col justify-end align-end">
          <div class="preferential" v-if="orderData.superVip">
            特权用户0元投放：<span>-¥{{ orderData.discountAmount }}</span>
          </div>
          <div class="preferential" v-if="orderData.useNewDiscount">
            新人1元体验：<span>-¥{{ orderData.discountAmount }}</span>
          </div>
          <div class="preferential" v-if="orderData.useFreeVipTime">
            账户剩余广告时长：<span>-¥{{ orderData.discountAmount }}</span>
          </div>
          <div class="total">
            总计：<span>¥{{ orderData.payAmount }}</span>
          </div>
        </div>
      </div>
    </div>
    <re-launch ref="refReLaunch" :launchData="orderData"></re-launch>
    <pay-type
      ref="refPayType"
      :propsData="payTypeData"
      @refreshPayStatus="getMyOrder($route.query.orderId)"
      @refreshOrderStatus="handRefreshStatus"
    ></pay-type>
    <pre-video-dialog
      ref="refPreVideo"
      :videoData="preVideoData"
    ></pre-video-dialog>
    <cancel-order-dialog
      ref="refCancelOrder"
      @cancelOrder="cancelOrderOk"
    ></cancel-order-dialog>
    <share-order-dialog
      :shareData="shareData"
      ref="refShareOrder"
    ></share-order-dialog>
    <edit-video-cover
      :videoUrl="orderData.videoUrl"
      :orderId="orderData.orderId"
      :videoThumbnailUrl="orderData.videoThumbnailUrl"
      @editVideoCoverSuccess="editVideoCoverSuccess"
      ref="refEditVideoCover"
    ></edit-video-cover>
    <downloadPDF
      :orderId="orderData.orderId"
      ref="refDownloadPDF"
    ></downloadPDF>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */

import {
  getOrder, getOrderTimer, getListAd, cancelOrder
} from "@/api/request";
import reLaunch from "@/components/reLaunch.vue";
import payType from "@/components/payTypeDialog.vue";
import preVideoDialog from "@/components/preVideoDialog.vue";
import cancelOrderDialog from "@/components/cancelOrderDialog.vue";
import shareOrderDialog from "@/components/shareOrderDialog.vue";
import downloadPDF from "@/components/downloadPDF.vue";
import canUseCanva from "@/utils/canUseCanva";
import editVideoCover from "./components/editVideoCover.vue";

export default {
  components: {
    reLaunch,
    payType,
    preVideoDialog,
    cancelOrderDialog,
    shareOrderDialog,
    downloadPDF,
    editVideoCover,
  },
  data() {
    return {
      reverse: false,
      showTips: true,
      orderData: {},
      payTypeData: {}, // 立即支付数据
      adList: [], // 投放中和已结束状态下的广告表格数据
      preVideoData: {}, // 预览视频数据
      minute: 0,
      second: 0,
      currentTab: "all",
      countDownTimer: null,
      active: null,
      status3Num: 0,
      status6Num: 0,
      status7Num: 0,
      status2Num: 0,
      currentOrderId: null,
      pageParams: {
        statusList: [],
        orderId: "",
        Asc: true, // 升序排列
        name: "", // 屏幕名称
        pageLimit: {
          limit: 50,
          page: 1,
        },
      },
      currentPages: null,
      shareData: {}, // 分享弹窗的数据
      infiniteScrollDisabled: false,
      overBeginUnix: false, // 是否是超过第一次投放时间被取消了
      showTypeMap: [],
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      getOrderTimer: null,
    };
  },
  created() {
    this.pageParams.orderId = this.$route.query.orderId;
    this.infiniteScrollDisabled = false;
    this.getMyOrder(this.$route.query.orderId);
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    refundTimeDesc() {
      let desc = "";
      if (this.orderData.payType == "1") {
        desc = "已退回到您的微信账号，可前往“微信-账单”查看";
      }
      if (this.orderData.payType == "2" || this.orderData.payType == "4") {
        desc = "已退回到您的支付宝账号，可前往“支付宝-账单”查看";
      }
      if (this.orderData.payType == "3") {
        desc = "已退回到您的支付宝花呗账号，可前往“支付宝-花呗”查看";
      }
      return desc;
    },
    // 获取用户的信息
    userInfoObj() {
      return this.$store.state.userInfo;
    },
    // 判断当前时间是否在22:00 - 23:59:59
    isBetween() {
      const time = new Date();
      if (time.getHours() < 22) {
        return true;
      }
      return false;
    },
  },
  methods: {
    getMyOrder(orderId) {
      getOrder({
        order_id: orderId,
        include_ad: false,
      })
        .then((res) => {
          if (res.order.status == "0") {
            this.active = 1;
            this.countDown(res.order.cancelTime);
          }
          if (res.order.status == "1" || res.order.status == "6") {
            this.active = 2;
          }
          if (res.order.status == "3") {
            this.active = 3;
          }
          if (
            res.order.status == "2" ||
            res.order.status == "4" ||
            res.order.status == "5"
          ) {
            this.active = 4;
          }
          this.orderData = res.order;
          this.showTypeMap = Object.entries(res.order.showTypeMap);
          // 需要广告的场景，请求广告数据
          if (
            res.order.status == "0" ||
            res.order.status == "1" ||
            res.order.status == "3" ||
            res.order.status == "5" ||
            res.order.status == "6"
          ) {
            this.getListAdEvent();
          }
          // 获取投放的广告表格统计信息
          if (res.order.status == "3" || res.order.status == "5") {
            this.getListAdNum(3);
            this.getListAdNum(6);
            this.getListAdNum(7);
          }
          if (res.order.status == "5") {
            this.getListAdNum(2);
          }
          // 订单状态为投放中，定时器请求订单信息
          if (res.order.status == "3" && !this.getOrderTimer) {
            this.getOrderTimer = setInterval(() => {
              this.setIntervalGetOrder();
            }, 1000);
          }
        })
        .catch((code) => {
          if (code == "20017" || code == "10001") {
            // 订单不存在
            this.$router.replace({ path: "/error" });
          }
        });
    },
    setIntervalGetOrder() {
      getOrderTimer({
        order_id: this.orderData.orderId,
        include_ad: false,
      }).then((res) => {
        this.status3Num = res.order.adWaitPlay;
        this.status6Num = res.order.adSuccessPlay;
        this.status7Num = res.order.adFailPlay;
        this.status2Num = res.order.adCancel;
        if (res.order.status == "5") {
          this.clearTimer();
          return;
        }
        // 非投放中并且有下次广告，计算倒计时
        if (!res.order.hasAdPlaying && res.order.hasNextAd) {
          this.updateCountdown(res.order.nextAdSecond);
        }
      });
    },
    updateCountdown(nextAdSecond) {
      const diff = nextAdSecond * 1000;
      // 计算时间差
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      // 更新页面元素
      if (days > 0) {
        this.days = days;
      } else {
        this.days = 0;
      }
      if (days > 0 || hours > 0) {
        this.hours = hours;
      } else {
        this.hours = 0;
      }
      if (days > 0 || hours > 0 || minutes > 0) {
        this.minutes = minutes;
      } else {
        this.minutes = 0;
      }
      this.seconds = seconds;
    },
    // 获取广告数据
    getListAdEvent() {
      this.pageParams.pageLimit.page = 1;
      getListAd(this.pageParams).then((res) => {
        this.currentPages = res.pageResult.pages;
        this.adList = res.adList;
        // 重新赋值点击状态的广告数量
        if (this.pageParams.statusList[0] == 3) {
          this.status3Num = res.pageResult.total;
        }
        if (this.pageParams.statusList[0] == 6) {
          this.status6Num = res.pageResult.total;
        }
        if (this.pageParams.statusList[0] == 7) {
          this.status7Num = res.pageResult.total;
        }
        if (this.pageParams.statusList[0] == 2) {
          this.status2Num = res.pageResult.total;
        }
      });
    },
    // 分页获取广告数据
    getPageListAdEvent() {
      getListAd(this.pageParams)
        .then((res) => {
          this.currentPages = res.pageResult.pages;
          this.adList = [...this.adList, ...res.adList];
          this.$nextTick(() => {
            this.infiniteScrollDisabled = false;
          });
        })
        .catch(() => {
          if (this.pageParams.pageLimit.page > 1) {
            this.pageParams.pageLimit.page--;
            this.infiniteScrollDisabled = false;
          }
        });
    },
    // 获取投放的广告表格统计信息
    getListAdNum(status) {
      const params = {
        statusList:
          status == 2
            ? ["2", "4", "5"]
            : status == 3
              ? ["3"]
              : status == 6
                ? ["6"]
                : ["7"],
        orderId: this.$route.query.orderId,
        Asc: true,
        name: "", // 屏幕名称
        pageLimit: {
          limit: "1",
          page: "1",
        },
      };
      getListAd(params).then((res) => {
        if (status == 3) {
          this.status3Num = res.pageResult.total;
        }
        if (status == 6) {
          this.status6Num = res.pageResult.total;
        }
        if (status == 7) {
          this.status7Num = res.pageResult.total;
        }
        if (status == 2) {
          this.status2Num = res.pageResult.total;
        }
      });
    },
    // 投放的广告时间区域下拉到底分页加载
    launchTimeLoad() {
      if (this.pageParams.pageLimit.page < this.currentPages) {
        this.pageParams.pageLimit.page++;
        console.log(
          "触发了下拉到底，进行分页请求，请求页码为",
          this.pageParams.pageLimit.page
        );
        this.infiniteScrollDisabled = true;
        this.getPageListAdEvent();
      }
    },
    handleClick(tab) {
      // console.log(tab.name);
      if (tab.name != "all") {
        if (tab.name == 3) {
          this.pageParams.statusList = ["3"];
          this.getListAdEvent();
          return;
        }
        if (tab.name == 6) {
          this.pageParams.statusList = ["6"];
          this.getListAdEvent();
          return;
        }
        if (tab.name == 7) {
          this.pageParams.statusList = ["7"];
          this.getListAdEvent();
        }
        if (tab.name == 2) {
          this.pageParams.statusList = ["2", "4", "5"];
          this.getListAdEvent();
        }
      } else {
        // 点击全部投放
        this.pageParams.statusList = [];
        this.getListAdEvent();
      }
    },
    onClick_see(item) {
      this.preVideoData = {
        dialogUrl: `${item.videoUrl}?${new Date().getTime()}`,
        currentScreen: this.orderData.name,
      };
      setTimeout(() => {
        this.$refs.refPreVideo.openDialog();
      }, 100);
    },
    pay() {
      this.payTypeData = {
        orderId: this.orderData.orderId,
        payType: this.orderData.payType,
        payAmount: this.orderData.payAmount,
        direct: this.orderData.direct,
        beginUnix: this.orderData.beginUnix,
      };
      setTimeout(() => {
        this.$refs.refPayType.openPayType();
      }, 100);
    },
    countDown(futureTime) {
      // currentTime当前时间戳，futureTime接口拿到的截止时间戳
      const currentTime = parseInt(new Date().getTime() / 1000);
      if (currentTime >= futureTime) {
        return false;
      }
      let differSecond = futureTime - currentTime; // 时间差
      const vm = this;
      function fn() {
        vm.minute =
          parseInt(differSecond / 60) < 10
            ? `0${parseInt(differSecond / 60)}`
            : parseInt(differSecond / 60);
        vm.second =
          parseInt(differSecond - vm.minute * 60) < 10
            ? `0${parseInt(differSecond - vm.minute * 60)}`
            : parseInt(differSecond - vm.minute * 60);
        if (vm.minute == "00" && vm.second == "00") {
          vm.clearCountDownTimer();
          setTimeout(() => {
            vm.getMyOrder(vm.orderData.orderId);
          }, 1000);
        }
        differSecond--;
        return fn;
      }
      this.countDownTimer = setInterval(fn(), 1000);
    },
    clearCountDownTimer() {
      if (this.countDownTimer) {
        clearInterval(this.countDownTimer);
        this.countDownTimer = null;
      }
    },
    clearTimer() {
      if (this.getOrderTimer) {
        clearInterval(this.getOrderTimer);
        this.getOrderTimer = null;
      }
    },
    onCancelOrder(id) {
      this.currentOrderId = id;
      this.$refs.refCancelOrder.openCancelOrder();
    },
    cancelOrderOk() {
      cancelOrder({
        order_id: this.currentOrderId,
      })
        .then(() => {
          this.getMyOrder(this.currentOrderId);
          this.currentOrderId = "";
        })
        .catch((code) => {
          // 订单已经支付/超时了
          if (code == "20012" || code == "20013") {
            this.getMyOrder(this.currentOrderId);
          }
          this.currentOrderId = "";
        });
    },
    // 修改再投放
    onAmend() {
      // 判断能否使用Canva
      canUseCanva()
        .then(() => {
          this.$refs.refReLaunch.openreLaunch();
        })
        .catch(() => {});
    },
    // 再次投放（编辑投放）
    onRelaunch() {
      this.$refs.refReLaunch.openreLaunch();
    },
    // 分享订单
    onShareOrder() {
      this.shareData = {
        orderId: this.orderData.orderId,
        userName: this.orderData.userName,
        userImage: this.orderData.userImage,
      };
      setTimeout(() => {
        this.$refs.refShareOrder.openShareOrder();
      }, 100);
    },
    // 修改视频封面
    editVideoCover() {
      this.$refs.refEditVideoCover.openDialog();
    },
    editVideoCoverSuccess() {
      this.getMyOrder(this.$route.query.orderId);
    },
    handRefreshStatus() {
      this.clearCountDownTimer();
      this.orderData.status = 2;
      this.overBeginUnix = true;
    },
    goDurationTable() {
      this.$router.push({ name: "DurationTable" });
    },
    onDownloadPDF() {
      setTimeout(() => {
        this.$refs.refDownloadPDF.init();
      }, 100);
    },
  },
  beforeDestroy() {
    this.clearCountDownTimer();
    this.clearTimer();
  },
};
</script>

<style lang="scss" scoped>
@import "./detail.scss";

::v-deep .el-breadcrumb {
  margin-bottom: 34rem;
  font-size: 14rem;
}

::v-deep .el-divider {
  margin: 0 2.5%;
  height: 2rem;
  background-color: #f9f9f9;
}

::v-deep .el-divider--horizontal {
  width: 95%;
}

::v-deep .el-timeline-item__tail {
  border-left: 1rem dashed #cfd0d0;
}

::v-deep .el-timeline-item__node {
  background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
}

::v-deep .el-timeline-item__timestamp {
  font-size: 16rem;
  color: #939593;
  margin-left: 12rem;
}

::v-deep .el-timeline-item__content {
  font-size: 16rem;
}

::v-deep .el-timeline-item__dot {
  top: 40rem;
  left: 28rem;
  color: #909399;
  font-size: 13rem;
}

.el-timeline {
  position: relative;
  right: 1%;
}

.el-timeline-item {
  position: relative;

  .desc {
    position: absolute;
    top: 50rem;
    left: 40rem;
    font-size: 16rem;
    color: #909399;
  }
}

::v-deep .el-tabs__item {
  height: 62rem;
  line-height: 62rem;
  font-size: 16rem;
}

::v-deep .el-table thead {
  font-size: 16rem;
  color: #acaeac;
}

::v-deep .el-table td.el-table__cell div {
  font-size: 16rem;
  color: #3b3e3c;
}

::v-deep .el-table th.el-table__cell.is-leaf {
  // border: none;
  border-bottom: 2rem solid #f9f9f9;
}

::v-deep .el-table__row > td {
  // border: none;
  border-bottom: 2rem solid #f9f9f9;
}

::v-deep .el-table::before {
  height: 0rem;
}
</style>
